import { cn } from "@/lib/utils";
import { useStore } from "@/hooks/useStore";
import { useSidebarToggle } from "@/hooks/useSidebarToggle";
import { SidebarHeader } from "./SidebarHeader";
import { Menu } from "./Menu";

export function Sidebar() {
  const sidebar = useStore(useSidebarToggle, (state) => state);

  if (!sidebar) return null;

  const isOpen = sidebar.isOpen ?? false;
  const setIsOpen = sidebar.setIsOpen;

  const handleBorderClick = () => {
    setIsOpen();
  };

  return (
    <aside
      className={cn(
        "fixed top-0 left-0 z-20 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300",
        isOpen ? "w-56" : "w-14"
      )}
    >
      <div className="relative h-full flex flex-col px-2 pb-2 overflow-y-auto bg-neutral-50 dark:bg-neutral-900 border-r border-neutral-200 dark:border-neutral-800 overflow-hidden">
        <SidebarHeader isOpen={isOpen} />
        <hr className="border-t border-neutral-200 dark:border-neutral-800 -mx-2 mb-4" />
        <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div
        className={cn(
          "absolute top-0 -right-1 w-0.5 px-1 h-full group",
          isOpen ? "cursor-w-resize" : "cursor-e-resize"
        )}
        onClick={handleBorderClick}
      >
        <div
          className={cn(
            "absolute top-0 left-0.5 w-0.5 h-full group-hover:bg-primary/30 transition-colors"
          )}
        ></div>
      </div>
    </aside>
  );
}
