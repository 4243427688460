"use client";

import { cn } from "@/lib/utils";
import { useStore } from "@/hooks/useStore";
import { useSidebarToggle } from "@/hooks/useSidebarToggle";
import { Sidebar } from "@/components/Sidebar";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Navbar } from "@/components/Navbar/Navbar";

export default function DefaultLayout({
  children,
  navbarTitle = "",
  noContentPadding = false,
}: {
  children: React.ReactNode;
  navbarTitle?: string;
  noContentPadding?: boolean;
}) {
  const sidebar = useStore(useSidebarToggle, (state) => state);

  if (!sidebar) return null;

  const isOpen = sidebar.isOpen ?? false;

  return (
    <>
      <Sidebar />
      <main
        className={cn(
          "min-h-screen bg-white dark:bg-neutral-950 transition-[margin-left] ease-in-out duration-300",
          isOpen ? "lg:ml-56" : "lg:ml-14"
        )}
      >
        <div className="lg:hidden">
          <Navbar title={navbarTitle} />
        </div>
        <ScrollArea className="h-[calc(100%-57px)] lg:h-screen">
          <div
            className={cn(
              "",
              !noContentPadding && "container p-4 sm:p-8 lg:px-24 lg:py-8"
            )}
          >
            {children}
          </div>
        </ScrollArea>
      </main>
    </>
  );
}
