"use client";

import { ScrollArea } from "@/components/ui/scroll-area";
import { useMenuList } from "./lib/MenuList";
import { RenderMenuItem } from "./RenderMenuItem";
import { SidebarToggle } from "../SidebarToggle";
import { Separator } from "@/components/ui/separator";

interface MenuProps {
  isOpen: boolean | undefined;
  setIsOpen: (isOpen: boolean) => void;
}

export function Menu({ isOpen, setIsOpen }: MenuProps) {
  const menuList = useMenuList();

  const regularGroups = menuList.filter(
    ({ groupLabel }) => groupLabel !== "end"
  );
  const endGroups = menuList.filter(({ groupLabel }) => groupLabel === "end");

  const renderMenuItem = (item: any, idx: number) => {
    if (item.type === "component") {
      const Component = item.component;
      return <Component key={idx} />;
    }
    return <RenderMenuItem key={idx} item={item} isOpen={isOpen} />;
  };

  return (
    <nav className="h-full w-full flex flex-col">
      <ScrollArea className="flex-grow overflow-y-auto">
        <ul className="flex flex-col space-y-1">
          {regularGroups.map(({ items }, groupIndex) => (
            <li className="w-full" key={groupIndex}>
              {items.map((item, idx) => renderMenuItem(item, idx))}
            </li>
          ))}
        </ul>
      </ScrollArea>
      {(endGroups.length > 0 || true) && (
        <ul className="w-full mt-auto">
          <li className="w-full mb-1">
            <SidebarToggle isOpen={isOpen} setIsOpen={setIsOpen} />
          </li>
          <Separator className="mb-2" />
          {endGroups.flatMap(({ items }, groupIndex) =>
            items.map((item, idx) => (
              <li key={`${groupIndex}-${idx}`} className="w-full">
                {renderMenuItem(item, idx)}
              </li>
            ))
          )}
        </ul>
      )}
    </nav>
  );
}
