"use client";

import { LogOut, Settings, HelpCircle, Building2 } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import { ThemeToggle } from "@/components/Navbar/ThemeToggle";
import useUser from "@/hooks/useUser";
import { useLogout } from "@/hooks/useLogout";
import { useOrg } from "@/contexts/OrgContext";
import { cn } from "@/lib/utils"; // Make sure you have this utility function
import { useAuth0 } from "@auth0/auth0-react";

export function UserMenu() {
  const { user } = useAuth0();
  const { userName, email, isDriverAiUser } = useUser();
  const { organizationName } = useOrg();
  const { loginWithRedirect } = useAuth0();
  const logOut = useLogout();
  const router = useRouter();
  const [showEmail, setShowEmail] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setShowEmail(entry.contentRect.width > 31);
      }
    });

    if (triggerRef.current) {
      observer.observe(triggerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const getInitial = (name: string | undefined, email: string) => {
    if (name) {
      return name.charAt(0).toUpperCase();
    }
    return email.charAt(0).toUpperCase();
  };

  return (
    <div className="flex items-center justify-center pb-1 px-0.5">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            ref={triggerRef}
            variant="ghost"
            className="flex items-center w-full hover:bg-secondary-foreground/5 p-2 px-1"
          >
            <div className="flex rounded-full w-6 h-6 bg-secondary-foreground/5 items-center justify-center ml-0.5">
              <Avatar className="overflow-visible">
                <AvatarImage src="#" alt="Avatar" />
                <AvatarFallback className="bg-transparent">
                  {getInitial(userName, email)}
                </AvatarFallback>
              </Avatar>
            </div>
            <div
              className={cn(
                "flex flex-col overflow-hidden flex-grow text-left transition-all duration-300 ease-in-out",
                showEmail ? "w-full opacity-100 ml-3" : "max-w-0 opacity-0"
              )}
            >
              <p className="text-sm font-normal text-primary truncate">
                {email}
              </p>
            </div>
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          className="w-56"
          side="right"
          align="end"
          forceMount
        >
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1.5">
              {userName && userName !== email ? (
                <>
                  <p className="text-sm font-medium leading-none">{userName}</p>
                  <p className="text-xs leading-none text-muted-foreground">
                    {email}
                  </p>
                </>
              ) : (
                <p className="text-sm font-medium text-muted-foreground">
                  {email}
                </p>
              )}
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <ThemeToggle />
            {isDriverAiUser && (
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onClick={() => {
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: "organization",
                    },
                  });
                }}
              >
                <Building2 className="w-4 h-4 mr-3 text-muted-foreground" />
                Change organization
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onClick={() => {
                router.push(`/${organizationName}/settings/workspace`);
              }}
            >
              <Settings className="w-4 h-4 mr-3 text-muted-foreground" />
              Settings
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="hover:cursor-pointer" onClick={logOut}>
            <LogOut className="w-4 h-4 mr-3 text-muted-foreground" />
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
