import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import Image from "next/image";
import { SlashIcon, PlusIcon } from "@radix-ui/react-icons";
import { useOrg } from "@/contexts/OrgContext";
import { useCreateNewDocument } from "@/hooks/useCreateNewPage";
import { SquarePen } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

export function SidebarHeader({ isOpen }: { isOpen: boolean }) {
  const { organizationId, organizationName, organizationDisplayName } =
    useOrg();
  const { createNewDocument } = useCreateNewDocument();
  const { toast } = useToast();
  const handleCreatePage = () => {
    createNewDocument({ actionLocation: "sidebar", creationSource: "blank" })
      .then(() => {
        toast({
          title: "Page created",
          description: "Your new page is ready to use.",
          variant: "default",
        });
      })
      .catch((error) => {
        toast({
          title: "Failed to create Page",
          description: "Please try again.",
          variant: "destructive",
        });
      });
  };

  return (
    <div
      className={cn(
        "flex-shrink-0",
        isOpen
          ? "h-[56px] flex items-center"
          : "flex flex-col items-center pt-2"
      )}
    >
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <div className={cn(isOpen ? "flex-grow" : "w-full py-0.5")}>
              <Button
                className={cn(
                  "flex items-center w-full justify-start p-2.5 hover:bg-secondary-foreground/5",
                  isOpen ? "justify-start pe-1" : "px-0"
                )}
                variant="ghost"
                link={`/${organizationName}`}
              >
                <Image
                  src="/logomark_driver_bg.webp"
                  alt="Driver"
                  width={20}
                  height={20}
                  priority
                  className={cn(
                    "transition-all ease-in-out dark:invert transform",
                    isOpen ? "mr-1" : "mx-auto"
                  )}
                />
                <div
                  className={cn(
                    isOpen
                      ? "flex items-center gap-1 truncate"
                      : "w-0 opacity-0"
                  )}
                >
                  <SlashIcon className="w-3.5 h-3.5 opacity-40" />
                  <span className="text-sm whitespace-nowrap truncate">
                    {organizationDisplayName}
                  </span>
                </div>
              </Button>
            </div>
          </TooltipTrigger>
          <TooltipContent
            side="right"
            sideOffset={16}
            className={cn(isOpen ? "hidden" : "")}
          >
            {organizationDisplayName}
          </TooltipContent>
        </Tooltip>

        <hr
          className={cn(
            "w-[180%] border-t border-border my-2 -ms-4",
            isOpen ? "hidden" : ""
          )}
        />

        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <Button
              className={cn("p-2 hover:bg-primary/5", isOpen ? "" : "mb-2")}
              variant="ghost"
              size="icon"
              onClick={handleCreatePage}
            >
              <SquarePen className="w-4 h-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="right" sideOffset={16}>
            Create a new page
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
