import React from "react";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import useUppyPdfUpload from "./useUppyPdfUpload";

const PdfDropzone: React.FC = () => {
  const uppy = useUppyPdfUpload();

  return (
    <Dashboard
      id="dropzone"
      uppy={uppy}
      proudlyDisplayPoweredByUppy={false}
      showProgressDetails={false}
      theme="light"
      height={335}
      width={656}
      note="PDF only"
      locale={{
        pluralize: (n) => (n === 1 ? 0 : 1),
        strings: {
          dropPasteFiles: "Drop files here or %{browse}",
          browseFiles: "choose",
          dropHint: "Drop to add",
          cancel: "Remove files",
          done: "Add another resource",
          xFilesSelected: {
            0: "",
            1: "",
          },
          uploadComplete: "Upload successful.",
        },
      }}
    />
  );
};

export default PdfDropzone;
