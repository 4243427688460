import { useRouter } from 'next/router';
import { useCreateDocumentMutation } from "@/api/api";
import { track } from '@vercel/analytics';

export const useCreateNewDocument = () => {
  const router = useRouter();
  const [createDocument] = useCreateDocumentMutation();

  const createNewDocument = async ({
    actionLocation,
    creationSource
  }: {
    actionLocation: string;
    creationSource: 'blank' | 'template';
  }) => {
    try {
      const response = await createDocument();

      const { data } = response;
      if (!data) {
        throw new Error("Failed to create a new document.");
      }
      const { node_id } = data;
      const { organizationName } = router.query;

      track('create_page', {
        organizationName: organizationName as string,
        actionLocation: actionLocation,
        creationSource: creationSource,
      });

      const newPath = `/${organizationName}/page/${node_id}`;
      // TODO: Use router.push instead of window.location.href when we have a better way to handle the data
      // router.push(newPath);
      // NOTE: This is a temporary fix to prevent the new page from inheriting the content of the original page (if user is on a page)
      if (router.pathname.includes('/page/')) {
        window.location.href = newPath;
      } else {
        router.push(newPath);
      }
    } catch (error) {
      console.error('Error creating new document:', error);
      throw error;
    }
  };

  return { createNewDocument };
};
