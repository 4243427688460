import { useRouter } from 'next/router';
import {
  LucideIcon,
  Zap,
  Home,
  Layers,
  Tag
} from "lucide-react";
import { UserMenu } from "@/components/Navbar/UserMenu";
import { HelpMenu } from "@/components/Navbar/HelpMenu";
import { useGetTagsQuery } from '@/api/api';

type Submenu = {
  href: string;
  label: string;
  active: boolean;
  hex_color?: string;
};

type Menu = {
  href: string;
  label: string;
  active: boolean;
  icon: LucideIcon
  submenus: Submenu[];
};

type CustomComponent = React.ComponentType<any>;

type MenuItem = {
  type: 'link' | 'component';
  href?: string;
  label?: string;
  active?: boolean;
  icon?: LucideIcon;
  submenus?: Submenu[];
  component?: CustomComponent;
};

type Group = {
  groupLabel: string;
  items: MenuItem[];
};

export function useMenuList(): Group[] {
  const router = useRouter();
  const { pathname, query, asPath } = router;

  const organizationName = query.organizationName as string || asPath.split('/')[1];

  const isActive = (path: string) => asPath.includes(path);

  const { data: tagsData } = useGetTagsQuery({ type: "tag", limit: 1000, offset: 0 });

  // Safely access tags, ensuring it's an array
  const tags = Array.isArray(tagsData) ? tagsData : 
               (tagsData && Array.isArray(tagsData.results)) ? tagsData.results : 
               [];

  const isTagActive = (tagId: string) => {
    const columnFilters = query.columnFilters as string | undefined;
    if (columnFilters) {
      try {
        const filters = JSON.parse(decodeURIComponent(columnFilters));
        return filters.some((filter: { id: string; value: string[] }) => 
          filter.id === 'tags' && filter.value.includes(tagId)
        );
      } catch (error) {
        console.error('Error parsing columnFilters:', error);
        return false;
      }
    }
    return false;
  };

  // Update the tags mapping to include color
  const tagSubmenus = tags.map((tag: { id: string; name: string; hex_color?: string; }) => ({
    href: `/${organizationName}/library?columnFilters=${encodeURIComponent(JSON.stringify([{
      id: "tags",
      value: [tag.id]
    }]))}&pageIndex=0&pageSize=30`,
    label: tag.name,
    active: isTagActive(tag.id),
    hex_color: tag.hex_color
  })).sort((a, b) => a.label.localeCompare(b.label));

  const groups: Group[] = [
    {
      groupLabel: "Main",
      items: [
        {
          type: 'link',
          href: `/${organizationName}/`,
          label: "Home",
          active: asPath === `/${organizationName}` || asPath === `/${organizationName}/`,
          icon: Home,
          submenus: []
        },
        {
          type: 'link',
          href: `/${organizationName}/library`,
          label: "Library",
          active: isActive("/library"),
          icon: Layers,
          submenus: []
        },
        {
          type: 'link',
          href: `/${organizationName}/templates`,
          label: "Templates",
          active: isActive("/templates"),
          icon: Zap,
          submenus: []
        }
      ]
    },
    {
      groupLabel: "end",
      items: [
        {
          type: 'component',
          component: HelpMenu,
          label: "Help Menu",
        },
        {
          type: 'component',
          component: UserMenu,
          label: "User Menu",
        }
      ]
    }
  ];

  // Only add Tags to sidebare if there are tags in org
  if (tagSubmenus.length > 0) {
    groups.splice(1, 0, {
      groupLabel: "Tags",
      items: [
        {
          type: 'link' as const,
          href: `/${organizationName}/tags`,
          label: "Tags",
          active: isActive("/tags"),
          icon: Tag,
          submenus: tagSubmenus
        }
      ]
    });
  }

  return groups;
}
