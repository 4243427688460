import { useTheme } from "next-themes";
import { Moon, SunMedium, Monitor, Eclipse } from "lucide-react";

import { Button } from "@/components/ui/button";

export function ThemeToggle() {
  const { setTheme, theme, resolvedTheme } = useTheme();
  const displayTheme = theme
    ? theme.charAt(0).toUpperCase() + theme.slice(1)
    : "";

  return (
    <Button
      className="w-full justify-start ps-1"
      variant="ghost"
      onClick={() => {
        if (theme === "dark") {
          setTheme("light");
        } else if (theme === "light") {
          setTheme("system");
        } else {
          setTheme("dark");
        }
      }}
    >
      <div className="w-6 h-6 flex items-center justify-center">
        <Moon
          className={`w-[1rem] h-[1rem] transition-transform ease-in-out duration-500 ${theme === "dark" ? "scale-100" : "scale-0"}`}
        />
        <SunMedium
          className={`absolute w-[1rem] h-[1rem] transition-transform ease-in-out duration-500 ${theme === "light" ? "scale-100" : "scale-0"}`}
        />
        <Eclipse
          className={`absolute w-[1rem] h-[1rem] transition-transform ease-in-out duration-500 ${theme === "system" ? "scale-100" : "scale-0"}`}
        />
      </div>
      <span className="ml-2 text-sm font-normal">
        Change theme{" "}
        <span className="text-muted-foreground">({displayTheme})</span>
      </span>
      <span className="sr-only">Change Theme</span>
    </Button>
  );
}
