import Link from "next/link";
import { MenuIcon } from "lucide-react";
import { SlashIcon } from "@radix-ui/react-icons";
import Image from "next/image";
import { cn } from "@/lib/utils";

import { Button } from "@/components/ui/button";
import { Menu } from "./Menu";
import {
  Sheet,
  SheetHeader,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useOrg } from "@/contexts/OrgContext";

export function SidebarMobile() {
  const { organizationName, organizationDisplayName } = useOrg();

  return (
    <Sheet>
      <SheetTrigger className="lg:hidden" asChild>
        <Button className="h-8" variant="ghost" size="icon">
          <MenuIcon size={18} className="opacity-70" />
        </Button>
      </SheetTrigger>
      <SheetContent className="sm:w-72 px-3 h-full flex flex-col" side="left">
        <SheetHeader>
          <Button
            className="flex justify-start items-center pb-2 pt-1"
            variant="link"
            asChild
          >
            <Link
              href={`/${organizationName}`}
              className="flex items-center gap-2 ps-2"
            >
              <Image
                src="/logomark_driver_bg.webp"
                alt="Driver"
                width={22}
                height={22}
                priority
                className={cn(
                  "transition-all ease-in-out dark:invert transform hover:rotate-90"
                )}
              />
              <SlashIcon className="w-4 h-4 opacity-50" />
              <div className="text-sm">{organizationDisplayName}</div>
            </Link>
          </Button>
        </SheetHeader>
        <hr className="border-t border-neutral-200 dark:border-neutral-800 -mx-3" />
        <Menu isOpen setIsOpen={() => {}} />
      </SheetContent>
    </Sheet>
  );
}
