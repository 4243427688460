import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

interface MenuItemProps {
  href: string;
  label: string;
  Icon: any;
  active: boolean;
  isOpen: boolean | undefined;
}

export const MenuButton = ({
  href,
  label,
  Icon,
  active,
  isOpen,
}: MenuItemProps) => {
  return (
    <TooltipProvider disableHoverableContent>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <Button
            variant={active ? "secondary" : "ghost"}
            className={cn(
              "w-full justify-start p-2.5 hover:bg-secondary-foreground/5",
              isOpen === false && "justify-center",
              active ? "bg-secondary-foreground/10" : ""
            )}
            link={href}
          >
            <span
              className={cn(
                isOpen === false ? "mr-0" : "mr-3",
                "flex items-center justify-center"
              )}
            >
              <Icon size={16} />
            </span>
            <div
              className={cn(
                "truncate",
                isOpen === false ? "hidden" : "translate-x-0 opacity-100"
              )}
            >
              {label}
            </div>
          </Button>
        </TooltipTrigger>
        {isOpen === false && (
          <TooltipContent side="right" sideOffset={16} style={{ zIndex: 100 }}>
            {label}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};
