import { MenuButton } from "./MenuButton";
import { CollapseMenuButton } from "./CollapseMenuButton";

interface RenderMenuItemProps {
  item: any;
  isOpen: boolean | undefined;
}

export const RenderMenuItem = ({ item, isOpen }: RenderMenuItemProps) => {
  const { href, label, icon: Icon, active, submenus } = item;
  return (
    <div className="w-full">
      {submenus.length === 0 ? (
        <MenuButton
          href={href}
          label={label}
          Icon={Icon}
          active={active}
          isOpen={isOpen}
        />
      ) : (
        <CollapseMenuButton
          icon={Icon}
          label={label}
          active={active}
          submenus={submenus}
          isOpen={isOpen}
        />
      )}
    </div>
  );
};
