"use client";

import {
  HelpCircle,
  Keyboard,
  Mail,
  ArrowUpRight,
  Calendar,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import HelpWithDriver from "@/components/Guides/HelpWithDriver";
import KeyboardShortcuts from "@/components/Guides/KeyboardShortcuts";
import Link from "next/link";
import { cn } from "@/lib/utils";

export function HelpMenu() {
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const [isKeyboardShortcutsOpen, setIsKeyboardShortcutsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let prevWidth = 0;
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const currentWidth = entry.contentRect.width;
        if (
          (prevWidth <= 31 && currentWidth > 31) ||
          (prevWidth > 31 && currentWidth <= 31)
        ) {
          setShowHelp(currentWidth > 31);
        }
        prevWidth = currentWidth;
      }
    });

    if (triggerRef.current) {
      observer.observe(triggerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="flex items-center justify-center pb-1 px-0.5">
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            ref={triggerRef}
            variant="ghost"
            className="flex items-center w-full hover:bg-secondary-foreground/5 p-2 px-1.5 transition-all justify-start"
          >
            <div className="flex items-center justify-center w-7 h-7">
              <HelpCircle
                className="w-6 h-6 text-primary/80"
                strokeWidth={1.25}
              />
            </div>
            <div
              className={cn(
                "flex flex-col overflow-hidden flex-grow text-left transition-all duration-300 ease-in-out",
                showHelp ? "w-full opacity-100 ml-3" : "max-w-0 opacity-0"
              )}
            >
              <p className="text-sm font-normal text-primary truncate">Help</p>
            </div>
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-56" side="right" align="end">
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onClick={() => setIsHelpDialogOpen(true)}
          >
            <HelpCircle className="w-4 h-4 mr-3 text-muted-foreground" />
            How to use Driver
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onClick={() => setIsKeyboardShortcutsOpen(true)}
          >
            <Keyboard className="w-4 h-4 mr-3 text-muted-foreground" />
            Keyboard shortcuts
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link
              href="https://changelog.driver.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Calendar className="w-4 h-4 mr-3 text-muted-foreground" />
              What&apos;s new
              <ArrowUpRight className="w-3 h-3 ml-auto" />
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link
              href="mailto:support@driver.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Mail className="w-4 h-4 mr-3 text-muted-foreground" />
              Contact
              <ArrowUpRight className="w-3 h-3 ml-auto" />
            </Link>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={isHelpDialogOpen} onOpenChange={setIsHelpDialogOpen}>
        <DialogContent className="max-w-5xl p-8 overflow-y-scroll max-h-screen">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-xl font-medium">
              How to use Driver
            </DialogTitle>
          </DialogHeader>
          <HelpWithDriver />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isKeyboardShortcutsOpen}
        onOpenChange={setIsKeyboardShortcutsOpen}
      >
        <DialogContent className="max-w-xl p-8 pr-5 overflow-y-hidden max-h-screen">
          <DialogHeader className="mb-1">
            <DialogTitle className="text-xl font-medium">
              Keyboard shortcuts
            </DialogTitle>
          </DialogHeader>
          <KeyboardShortcuts />
        </DialogContent>
      </Dialog>
    </div>
  );
}
