"use client";

import { useState } from "react";
import { ChevronRight, LucideIcon, MoreHorizontal } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "@/components/ui/tooltip";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useRouter } from "next/router";

type Submenu = {
  href: string;
  label: string;
  active: boolean;
  hex_color?: string;
};

interface CollapseMenuButtonProps {
  icon: LucideIcon;
  label: string;
  active: boolean;
  submenus: Submenu[];
  isOpen: boolean | undefined;
}

export function CollapseMenuButton({
  icon: Icon,
  label,
  active,
  submenus,
  isOpen,
}: CollapseMenuButtonProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const visibleSubmenus = submenus.slice(0, 5);
  const hasMoreSubmenus = submenus.length > 5;

  const SubMenuContent = () => (
    <Command>
      <CommandInput
        placeholder={`Filter ${label.toLowerCase()}...`}
        className="h-auto pl-0 py-2 border-none focus:ring-0 active:ring-0"
      />
      <CommandList>
        <CommandEmpty>No tags found.</CommandEmpty>
        <CommandGroup>
          {submenus.map(({ href, label: submenuLabel, hex_color }, index) => (
            <CommandItem
              key={index}
              value={submenuLabel}
              onSelect={() => {
                router.push(href);
                setOpen(false);
              }}
              className="cursor-pointer"
            >
              <div
                className="w-1.5 h-1.5 rounded-full mr-2"
                style={{
                  backgroundColor: hex_color || "#eaeaea",
                }}
              />
              <p className="max-w-[180px] truncate">{submenuLabel}</p>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );

  return isOpen ? (
    <Collapsible
      open={isCollapsed}
      onOpenChange={setIsCollapsed}
      className="w-full mt-3"
    >
      <CollapsibleTrigger
        className="[&[data-state=open]>div>div>svg]:rotate-90"
        asChild
      >
        <Button
          variant={active ? "secondary" : "ghost"}
          className={cn(
            "w-full justify-start h-auto py-1 font-normal",
            active
              ? "bg-secondary-foreground/10 hover:bg-secondary-foreground/5"
              : "hover:bg-secondary-foreground/5"
          )}
        >
          <div className="w-full items-center flex justify-start gap-1 text-muted-foreground">
            <div className="flex items-center">
              <div
                className={cn(
                  "truncate",
                  isOpen ? "translate-x-0 opacity-100" : "hidden"
                )}
              >
                {label}
              </div>
            </div>
            <div
              className={cn(
                "whitespace-nowrap",
                isOpen
                  ? "translate-x-0 opacity-100"
                  : "-translate-x-96 opacity-0"
              )}
            >
              <ChevronRight
                size={14}
                className="transition-transform duration-200"
              />
            </div>
          </div>
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down relative max-h-[240px] overflow-y-auto pl-1">
        {visibleSubmenus.map(({ href, label, active, hex_color }, index) => (
          <Button
            variant="ghost"
            className={cn(
              "w-full justify-start relative py-1.5  h-auto text-sm text-muted-foreground font-normal",
              active && "text-primary font-medium"
            )}
            key={index}
            link={href}
          >
            <div
              className="w-1.5 h-1.5 rounded-full mr-2.5"
              style={{
                backgroundColor: hex_color || "#eaeaea",
              }}
            ></div>
            <div className="truncate">{label}</div>
          </Button>
        ))}
        {hasMoreSubmenus && (
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="ghost"
                className="w-full justify-start relative py-1.5 px-2 h-auto text-sm text-muted-foreground font-normal"
              >
                <MoreHorizontal size={14} className="mr-2" />
                <div className="truncate">More</div>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              side="right"
              sideOffset={16}
              align="start"
              className="w-[200px] p-0"
            >
              <SubMenuContent />
            </PopoverContent>
          </Popover>
        )}
      </CollapsibleContent>
    </Collapsible>
  ) : (
    <Popover open={open} onOpenChange={setOpen}>
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <Button
                variant={active ? "secondary" : "ghost"}
                className={cn("w-full justify-start")}
              >
                <div className="w-full items-center flex justify-between">
                  <div className="flex items-center">
                    <span className={cn(isOpen === false ? "" : "mr-3")}>
                      <Icon size={16} />
                    </span>
                    <p
                      className={cn(
                        "max-w-[200px] truncate",
                        isOpen === false ? "opacity-0" : "opacity-100"
                      )}
                    >
                      {label}
                    </p>
                  </div>
                </div>
              </Button>
            </PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent side="right" align="center" sideOffset={16}>
            {label}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <PopoverContent
        side="right"
        sideOffset={16}
        align="start"
        className="w-[200px] p-0"
      >
        <SubMenuContent />
      </PopoverContent>
    </Popover>
  );
}
