import { UserMenu } from "@/components/Navbar/UserMenu";
import { SidebarMobile } from "@/components/Sidebar/SidebarMobile";
import { decode } from "he";

interface NavbarProps {
  title: string;
}

export function Navbar({ title }: NavbarProps) {
  return (
    <header className="sticky top-0 z-10 w-full bg-white dark:bg-neutral-950 border-b border-neutral-200 dark:border-neutral-800">
      <div className="mx-4 lg:ms-12 sm:me-4 flex h-14 items-center">
        <div className="flex items-center space-x-2 lg:space-x-0">
          <SidebarMobile />
          <div className="text-sm text-muted-foreground">
            {title ? decode(title) : "Untitled"}
          </div>
        </div>
        <div className="flex flex-1 items-center space-x-2 justify-end">
          <UserMenu />
        </div>
      </div>
    </header>
  );
}
