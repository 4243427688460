import { useOrg } from "@/contexts/OrgContext";
import { Button } from "@/components/ui/button";
import { useCreateNewDocument } from "@/hooks/useCreateNewPage";
import { Card } from "@/components/ui/card";
import {
  ArrowUpFromLine,
  Code,
  File,
  FileSpreadsheet,
  GitCommitHorizontal,
} from "lucide-react";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import PdfDropzone from "../UploadService/PdfDropzone";

export default function HelpWithDriver() {
  const { organizationName } = useOrg();
  const { createNewDocument } = useCreateNewDocument();
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);

  const handleNewFile = () => {
    setIsFileDialogOpen(true);
  };

  return (
    <div className="flex flex-col">
      <div className="max-w-4xl w-full">
        <ol className="space-y-10">
          <li>
            <div className="flex flex-col md:flex-row items-start mb-4 gap-y-2 w-full">
              <div className="flex-shrink-0 w-7 h-7 bg-primary text-background rounded flex items-center justify-center mr-4 text-base font-mono">
                1
              </div>
              <div className="flex flex-col space-y-1 flex-grow">
                <h4 className="text-xl font-mono font-semibold text-primary">
                  Add sources
                </h4>
                <div className="text text-primary/75">
                  Sources are the building blocks of Driver, use them as a
                  knowledge base to generate docs and reference in Pages.
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:pl-10">
              <Card className="p-4 rounded-lg">
                <div className="flex flex-col mb-4">
                  <Code className="w-6 h-6 mb-2" />
                  <h5 className="text-base font-semibold font-mono">
                    Codebase
                  </h5>
                </div>
                <ul className="list-[square] pl-5 mb-4 space-y-2 text-primary/80 text-sm">
                  <li>Auto-generated documentation for all files</li>
                  <li>Connect to Git Repositories</li>
                  <li>Supports most languages and frameworks</li>
                </ul>
                <Button
                  variant="secondary"
                  link={`/${organizationName}/new`}
                  className="w-full"
                >
                  <GitCommitHorizontal className="w-4 h-4 mr-1.5" />
                  Connect a codebase
                </Button>
              </Card>
              <Card className="p-4 rounded-lg">
                <div className="flex flex-col mb-4">
                  <FileSpreadsheet className="w-6 h-6 mb-2" />
                  <h5 className="text-base font-semibold font-mono">PDF</h5>
                </div>
                <ul className="list-[square] pl-5 mb-4 space-y-2 text-primary/75 text-sm">
                  <li>Search across all pages</li>
                  <li>Understands images and tables</li>
                  <li>No page limit</li>
                </ul>
                <Button
                  variant="secondary"
                  link={`/${organizationName}/new/pdf`}
                  className="w-full"
                >
                  <ArrowUpFromLine className="w-3.5 h-3.5 mr-1.5" />
                  Upload a PDF
                </Button>
              </Card>
            </div>
          </li>
          <li className="flex items-start">
            <div className="flex flex-col md:flex-row items-start gap-y-2 w-full">
              <div className="flex-shrink-0 w-7 h-7 bg-primary text-background rounded flex items-center justify-center mr-4 text-base font-mono">
                2
              </div>
              <div className="flex flex-col space-y-1 flex-grow">
                <div className="flex flex-col space-y-1">
                  <h4 className="text-xl font-mono font-semibold text-primary">
                    Get answers
                  </h4>
                  <div className="text text-primary/75">
                    Dive into tech docs about a codebase or write your own
                    Pages.
                    <br />
                    Reference any source you add to Driver within a Page to
                    automatically generate content.
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-4 w-full pt-4">
                  <Card className="p-4 rounded-lg w-full">
                    <div className="flex flex-col mb-4">
                      <File className="w-6 h-6 mb-2" />
                      <h5 className="text-base font-semibold font-mono">
                        Page
                      </h5>
                    </div>
                    <ul className="list-[square] pl-5 mb-4 space-y-2 text-primary/75 text-sm">
                      <li>Generate content automatically</li>
                      <li>Reference any source in Driver</li>
                      <li>Highlight and edit content with AI</li>
                    </ul>
                    <Button
                      variant="secondary"
                      className="w-full"
                      onClick={() =>
                        createNewDocument({
                          actionLocation: "help",
                          creationSource: "blank",
                        })
                      }
                    >
                      Start writing
                    </Button>
                  </Card>
                  <Card className="p-4 rounded-lg w-full bg-primary/10">
                    <div className="flex flex-col mt-3 mb-4">
                      <h5 className="text-base font-semibold font-mono">
                        How can I use Pages?
                      </h5>
                    </div>
                    <ul className="list-[square] pl-5 space-y-2 text-primary/75 text-sm">
                      <li>Document API endpoints</li>
                      <li>Create onboarding guides</li>
                      <li>Write technical specifications</li>
                      <li>Generate project documentation</li>
                      <li>and more...</li>
                    </ul>
                  </Card>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <Dialog open={isFileDialogOpen} onOpenChange={setIsFileDialogOpen}>
        <DialogContent className="max-w-fit">
          <DialogHeader>
            <DialogTitle>
              <div className="font-medium">Upload files</div>
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-2">
            <PdfDropzone />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
