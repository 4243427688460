import React, { useState, useEffect } from 'react';
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";

const shortcutCategories = [
  {
    name: "Editor",
    subgroups: [
      {
        name: "Basic Editing",
        shortcuts: [
          { windows: "Ctrl S", mac: "⌘ S", description: "Save" },
          { windows: "Ctrl Z", mac: "⌘ Z", description: "Undo" },
          { windows: "Ctrl ⇧ Z", mac: "⌘ ⇧ Z", description: "Redo" },
        ]
      },
      {
        name: "Text Formatting",
        shortcuts: [
          { windows: "Ctrl B", mac: "⌘ B", description: "Bold" },
          { windows: "Ctrl I", mac: "⌘ I", description: "Italic" },
          { windows: "Ctrl U", mac: "⌘ U", description: "Underline" },
          { windows: "Ctrl ⇧ S", mac: "⌘ ⇧ S", description: "Strikethrough" },
          { windows: "Ctrl E", mac: "⌘ E", description: "Inline code" },
        ]
      },
      {
        name: "Structure",
        shortcuts: [
          { windows: "Ctrl Alt 1", mac: "⌘ ⌥ 1", description: "Heading 1" },
          { windows: "Ctrl Alt 2", mac: "⌘ ⌥ 2", description: "Heading 2" },
          { windows: "Ctrl Alt 3", mac: "⌘ ⌥ 3", description: "Heading 3" },
          { windows: "Ctrl ⇧ 8", mac: "⌘ ⇧ 8", description: "Bulleted list" },
          { windows: "Ctrl ⇧ 7", mac: "⌘ ⇧ 7", description: "Numbered list" },
          { windows: "Ctrl ⇧ 9", mac: "⌘ ⇧ 9", description: "Checklist" },
          { windows: "Ctrl Alt C", mac: "⌘ ⌥ C", description: "Code block" },
          { windows: "Ctrl ⇧ B", mac: "⌘ ⇧ B", description: "Blockquote" },
        ]
      },
      {
        name: "Smart Instructions",
        shortcuts: [
          { windows: "Ctrl Enter", mac: "⌘ Enter", description: "Save smart instruction" },
          { windows: "Ctrl ⇧ Enter", mac: "⌘ ⇧ Enter", description: "Run smart instruction" },
        ]
      },
    ]
  },
  {
    name: "Markdown",
    subgroups: [
      {
        name: "Headers",
        shortcuts: [
          { windows: "# then Space", mac: "# then Space", description: "Heading 1" },
          { windows: "## then Space", mac: "## then Space", description: "Heading 2" },
          { windows: "### then Space", mac: "### then Space", description: "Heading 3" },
        ]
      },
      {
        name: "Lists",
        shortcuts: [
          { windows: "- then Space", mac: "- then Space", description: "Bulleted list" },
          { windows: "1. then Space", mac: "1. then Space", description: "Numbered list" },
          { windows: "[] then Space", mac: "[] then Space", description: "Checklist" },
        ]
      },
      {
        name: "Text Formatting",
        shortcuts: [
          { windows: "*text*", mac: "*text*", description: "Italic" },
          { windows: "**Text**", mac: "**Text**", description: "Bold" },
          { windows: "~~Text~~", mac: "~~Text~~", description: "Strikethrough" },
          { windows: "`Code`", mac: "`Code`", description: "Inline code" },
        ]
      },
      {
        name: "Other Elements",
        shortcuts: [
          { windows: "``` then Space", mac: "``` then Space", description: "Code block" },
          { windows: "---", mac: "---", description: "Horizontal divider" },
          { windows: "> then Space", mac: "> then Space", description: "Blockquote" },
        ]
      },
    ]
  },
];

// Words that should not be styled as buttons
const nonButtonWords = ['then', 'or', 'and'];

const KeyboardShortcuts: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isMac, setIsMac] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsMac(/mac|iphone|ipad|ipod/.test(userAgent));
  }, []);

  const filteredCategories = shortcutCategories.map(category => ({
    ...category,
    subgroups: category.subgroups.map(subgroup => ({
      ...subgroup,
      shortcuts: subgroup.shortcuts.filter(shortcut =>
        shortcut.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (isMac ? shortcut.mac : shortcut.windows).toLowerCase().includes(searchTerm.toLowerCase())
      )
    })).filter(subgroup => subgroup.shortcuts.length > 0)
  })).filter(category => category.subgroups.some(subgroup => subgroup.shortcuts.length > 0));

  return (
    <div className="flex flex-col h-full">
      <div className="relative mb-4 mr-4">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search shortcuts"
          className="pl-8"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <ScrollArea className="flex-grow pr-4 max-h-[calc(100vh-15rem)]">
        {filteredCategories.map((category, categoryIndex) => (
          <div key={categoryIndex} className="pt-2 pb-8 last:pb-0">
            <div className="sticky -top-0.5 bg-background z-20">
              <h3 className="text-lg font-semibold py-2">{category.name}</h3>
            </div>
            {category.subgroups.map((subgroup, subgroupIndex) => (
              <div key={subgroupIndex} className="mb-4 last:mb-0">
                <div className="sticky top-10 bg-background z-10">
                  <h4 className="inline-block text-sm font-semibold text-primary/80 mb-2 border-b border-border w-full py-2">{subgroup.name}</h4>
                </div>
                <div className="space-y-0">
                  {subgroup.shortcuts.map((shortcut, index) => (
                    <div key={index} className="flex justify-between items-center text-sm text-muted-foreground hover:text-primary rounded-sm py-1.5 px-1 transition-colors hover:bg-primary/0 hover:font-semibold group">
                      <span>{shortcut.description}</span>
                      <div className="flex gap-1 items-center">
                        {(isMac ? shortcut.mac : shortcut.windows).split(" ").map((key, keyIndex) => (
                          nonButtonWords.includes(key.toLowerCase()) ? (
                            <span key={keyIndex} className="px-1 text-muted-foreground text-xs font-mono">
                              {key}
                            </span>
                          ) : (
                            <span key={keyIndex} className="border border-primary/20 shadow-sm text-muted-foreground px-2 py-0.5 rounded text-xs font-mono group-hover:bg-background group-hover:text-primary transition-colors group-hover:border-primary/80">
                              {key}
                            </span>
                          )
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </ScrollArea>
    </div>
  );
};

export default KeyboardShortcuts;
